import { graphql } from 'gatsby'
import React from 'react'

import webAnimation from '../../assets/blog/22/1.gif'
import mobileAnimation from '../../assets/blog/22/2.png'
import heroImg from '../../assets/blog/22/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Třiďte úkoly podle odhadovaného času" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Třiďte úkoly podle odhadovaného času" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak Flagis usnadňuje správu úkolů a komunikaci"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Všichni to známe. Ty momenty během dne, kdy čekáme ve frontě,
            v&nbsp;zácpě na dálnici, u lékaře nebo na veřejnou dopravu. Často se
            stává, že tyto chvíle necháme nevyužité, protože si myslíme, že na
            &quot;opravdovou&quot; práci je čas příliš krátký. Jak ale dokázat
            tento čas proměnit ve skutečně produktivní okamžiky?
          </p>
          <h3>1. Rozpoznání hodnoty krátkých časových úseků</h3>
          <p>
            Začneme tím, že přehodnotíme náš pohled na krátké časové úseky.
            Místo toho abychom je považovali za nedostatečné pro plnohodnotnou
            práci, je můžeme vnímat jako příležitost k vyřízení drobnějších
            úkolů, které se jinak mohou hromadit a zabírat čas vyhrazený pro
            větší projekty. Zabraňme prokrastinaci a řešme krátké úkoly, které
            často nejvíce odkládáme.
          </p>
          <h3>2. Jak na třídění úkolů podle času</h3>
          <p>
            Zde přichází na řadu aplikace Flagis, která nabízí jedinečnou
            možnost mít jeden úkol ve více složkách/kategoriích. To umožňuje
            třídit úkoly nejen podle priority, projektu či konkrétní kategorie,
            ale také podle odhadovaného času k jejich splnění.
          </p>
          <p>
            <ul>
              <li>
                <b>Úkoly na 5 minut</b> - telefonní hovory, vyhledávání
                potřebných informací, posílání dokumentů nebo tipů, o které vás
                někdo požádal. Tyto úkoly jsou ideální na vyřízení ve frontě
                nebo během krátkého čekání.
              </li>
              <li>
                <b>Úkoly na 10 minut</b> - kontrola e-mailu a odepisování,
                rychlé rezervace nebo objednávky. Tento čas můžete efektivně
                využít například při čekání na jídlo v restauraci.
              </li>
              <li>
                <b>Úkoly na 30 minut a více</b> - četba odborných článků,
                plánování projektů, příprava prezentací, poslech vašeho
                oblíbeného podcastu. Tyto úkoly si naplánujte na delší čekání,
                jako je zácpa na dálnici nebo čekání u lékaře.
              </li>
            </ul>
          </p>
          <h3>3. Využití Smart View v aplikaci Flagis</h3>
          <p>
            Smart View umožňuje rychle a efektivně vyhledávat úkoly podle
            různých kritérií, včetně odhadovaného času. Nemusíte složitě zadávat
            několik filtrů, stačí kliknout na požadovanou pozici ve Smart View a
            okamžitě máte přehled o úkolech, které můžete v dané chvíli udělat.
          </p>
          <h3>4. Praktické tipy pro začlenění do každodenního života</h3>
          <p>
            <ul>
              <li>
                <b>Začněte den určením úkolů</b> podle odhadovaného času k
                jejich splnění a zařaďte je do odpovídajících kategorií ve
                Flagisu.
              </li>
              <li>
                <b>Využijte každou volnou chvilku</b> k procházení složek s
                krátkými úkoly. Máte-li před sebou jen 5 minut čekání, podívejte
                se do složky &quot;Úkoly na 5 minut&quot;.
              </li>
              <li>
                <b>Hodnoťte svůj pokrok</b> na konci dne. Kolik úkolů z každé
                kategorie se vám podařilo splnit?
              </li>
            </ul>
          </p>
          <h3>Závěr</h3>
          <p>
            Proměňte každou nevyužitou volnou chvilku v produktivní okamžik s
            aplikací Flagis. Tříděním úkolů podle odhadovaného času k jejich
            splnění zajistíte, že žádná volná chvíle nezůstane promarněna.
            Organizujte si svůj čas efektivně a užijte si více volného času pro
            sebe a své blízké.
          </p>

          <div className="my-5">
            <a href={webAnimation} target="_blank" rel="noreferrer">
              <img
                src={webAnimation}
                alt="Demo web showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5">
            <a href={mobileAnimation} target="_blank" rel="noreferrer">
              <img
                src={mobileAnimation}
                alt="Demo mobile showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
